var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-view",attrs:{"id":"privacy-policy"}},[_c('div',{staticClass:"row w-100 m-0"},[_c('CustomNav')],1),_c('div',{staticClass:"row mt-5 w-100 pl-4"},[_c('div',{staticClass:"col-md-2 d-flex flex-column align-items-start mb-3"},[_c('ul',{staticClass:"list-group w-100"},[_c('li',{staticClass:"list-group-item list-group-item-action",class:{ 'active': _vm.activeLink === 'Runner' },attrs:{"role":"button"},on:{"click":function($event){return _vm.setActiveLink('Runner')}}},[_vm._v(" Runner ")]),_c('li',{staticClass:"list-group-item list-group-item-action",class:{ 'active': _vm.activeLink === 'Sender' },attrs:{"role":"button"},on:{"click":function($event){return _vm.setActiveLink('Sender')}}},[_vm._v(" Sender ")])])]),_c('div',{staticClass:"col-md-10 d-flex justify-content-center"},[_c('section',{attrs:{"id":"paper"}},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v(" Privacy Policy ")]),_c('hr'),(_vm.activeLink == 'Sender')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e(),(_vm.activeLink == 'Runner')?_c('div',[_c('p',[_vm._v("At Senderrand, we value the privacy and security of our runners' personal information. This privacy policy outlines how we collect, use, and protect your information as a runner on our platform. ")]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("At Senderrand, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and protect the information you provide to us when using our website and services.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Information We Collect")]),_c('p',[_vm._v("When you use the Senderrand app, we may collect the following types of information:")]),_c('ul',[_c('li',[_vm._v("Personal Information: We may collect personal information such as your name, email address, phone number, and mailing address when you create an account with us or place an order for our services. ")]),_c('li',[_vm._v("Payment Information: When you make a payment on Senderrand, we may collect payment information, such as credit / Debit card details, to process your transaction securely. ")]),_c('li',[_vm._v("Usage Information: We may collect information about how you use the Senderrand app, including your IP address, device information, pages visited, and actions taken on our platform.")]),_c('li',[_vm._v("Location Information: If you enable location services on your device, we may collect and store your location information to provide location-based services, such as estimating delivery times. ")]),_c('li',[_vm._v("Marketing Channels: We may collect information about your preferences for receiving marketing communications from us, such as email, text messages, or other channels.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("How We Use Your Information")]),_c('p',[_vm._v("We use the information we collect to:")]),_c('ol',[_c('li',[_vm._v("Provide and improve our services: We use your information to process orders, communicate with you, and personalize your experience with the Senderrand app. We also use it to improve our platform, services, and customer support.")]),_c('li',[_vm._v("Payment processing: We use your payment information to process transactions securely and prevent fraud. ")]),_c('li',[_vm._v("Analytics: We use usage information to analyze trends, track user activity, and gather demographic information for marketing and research purposes.")]),_c('li',[_vm._v("Communication: We may use your email address and phone number to send you important updates about your orders, promotions, and other service-related communications through your preferred marketing channels. ")]),_c('li',[_vm._v("Language Preference: We may collect information about your preferred language to communicate effectively with you.")]),_c('li',[_vm._v("Legal Requirements: We may use and disclose your information as required by law or to protect our legal rights, such as responding to legal requests or preventing fraud.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Information Sharing")]),_c('p',[_vm._v("We do not sell, rent, or trade your personal information with third parties for their marketing purposes. We may share your information with third-party service providers who assist us in operating our platform and providing our services. These service providers are contractually obligated to protect your information and may only use it for the purposes we specify. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Data Security")]),_c('p',[_vm._v("We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, please note that no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Your Rights")]),_c('p',[_vm._v("You have the right to review, update, and delete the personal information we have collected from you. You can do this by logging into your Senderrand account or contacting us directly. You also have the right to opt out of receiving marketing communications from us through your preferred marketing channels.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Children's Privacy")]),_c('p',[_vm._v("Senderrand is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child, we will take steps to delete the information as soon as possible.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Changes to this Privacy Policy")]),_c('p',[_vm._v("We may update this Privacy Policy from time to time. Any changes will be posted on our platform, and we will notify you of any material changes via email or through other means. Your continued use of the Senderrand app after any changes to this policy constitutes your acceptance of the updated policy. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Information We Collect")]),_c('p',[_vm._v("When you register as a runner on the Senderrand app, we may collect the following types of information: ")]),_c('ul',[_c('li',[_vm._v("Personal Information: We may collect personal information such as your name, email address, phone number, profile photo, and payment information (e.g., bank account or PayPal details) for payment processing.")]),_c('li',[_vm._v("Location Information: In order to fulfill user requests, our app constantly uses your location in the background while you are logged in as a runner. This allows us to match you with user requests for errand services based on your proximity to the task location.")]),_c('li',[_vm._v("Transaction Information: We may collect information about the tasks you complete, including the location, time, and details of each task, as well as any ratings or feedback provided by users.")]),_c('li',[_vm._v("Communication Information: We may collect information about your communication with users, including text or voice messages, as well as any support inquiries or feedback provided by users.")]),_c('li',[_vm._v("Device Information: We may collect information about the device you use to access the Senderrand app, including the device type, operating system, unique device identifiers, and mobile network information. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("How We Use Your Information")]),_c('p',[_vm._v("We use the information we collect from runners for the following purposes:")]),_c('ol',[_c('li',[_vm._v("Task Assignment: We use your location information to match you with user requests for errand services based on your proximity to the task location. ")]),_c('li',[_vm._v("Task Completion: We use your information to track the tasks you complete, including the location, time, and details of each task, to ensure efficient and accurate task completion. ")]),_c('li',[_vm._v("Communication: We may use your email address, phone number, and in-app messaging to communicate with you about task assignments, updates, and other service-related communications. ")]),_c('li',[_vm._v("Payment Processing: We may use your payment information to process payments for the tasks you complete as a runner. ")]),_c('li',[_vm._v("Service Improvement: We may use your information to analyze trends, track user activity, gather feedback, and improve our platform and services.")]),_c('li',[_vm._v("Marketing: We may use your information, including your email address and phone number, to send you marketing communications about Senderrand, including promotions, special offers, and updates, via email, SMS, or other marketing channels. You have the option to opt-out of receiving marketing communications at any time. ")]),_c('li',[_vm._v("Legal Requirements: We may use and disclose your information as required by law or to protect our legal rights, such as responding to legal requests or preventing fraud. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Information Sharing")]),_c('p',[_vm._v("We do not sell, rent, or trade your personal information with third parties for their marketing purposes. We may share your information with users who request your services as a runner on our platform. We may also share your information with third-party service providers who assist us in operating our platform and providing our services, such as payment processors, customer support providers, and data analytics providers. These service providers are contractually obligated to protect your information and may only use it for the purposes we specify.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Location Information")]),_c('p',[_vm._v("As a runner on the Senderrand app, your location information is constantly used in the background to match you with user requests for errand services based on your proximity to the task location. This information is essential for the proper functioning of our platform and to ensure timely and efficient task completion.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Data Security ")]),_c('p',[_vm._v("We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Children's Privacy")]),_c('p',[_vm._v("Our services are not intended for use by children under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately. If we become aware that we have collected personal information from a child under the age of 18, we will take steps to delete such information from our records.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Changes to This Privacy Policy ")]),_c('p',[_vm._v("We may update this privacy policy from time to time to reflect changes in our business practices or legal requirements. We will notify you of any material changes to this policy by posting the updated policy on our app or by other means of communication. Your continued use of the Senderrand app after any changes to this policy constitutes your acceptance of the updated policy.")])])
}]

export { render, staticRenderFns }