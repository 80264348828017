<template>
  <div id="privacy-policy" class="page-view">
    <div class="row w-100 m-0">
      <CustomNav />
    </div>
    <div class="row mt-5 w-100 pl-4">
      <div class="col-md-2 d-flex flex-column align-items-start mb-3">
        <ul class="list-group w-100">
          <li role="button" class="list-group-item list-group-item-action" :class="{ 'active': activeLink === 'Runner' }"
            @click="setActiveLink('Runner')">
            Runner
          </li>
          <li role="button" class="list-group-item list-group-item-action" :class="{ 'active': activeLink === 'Sender' }"
            @click="setActiveLink('Sender')">
            Sender
          </li>
        </ul>

      </div>
      <div class="col-md-10 d-flex  justify-content-center">
        <section id="paper">
          <h2 class="font-weight-bold">
            Privacy Policy
          </h2>
          <hr />
          <div v-if="activeLink == 'Sender'">

            <div>
              <p>At Senderrand, we are committed to protecting your privacy and ensuring the security of your personal
                information. This Privacy Policy explains how we collect, use, and protect the information you provide to
                us
                when using our website and services.</p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Information We Collect</h5>
              <p>When you use the Senderrand app, we may collect the following types of information:</p>
              <ul>
                <li>Personal Information: We may collect personal information such as your name, email address, phone
                  number, and mailing address when you create an account with us or place an order for our services.
                </li>
                <li>Payment Information: When you make a payment on Senderrand, we may collect payment information, such
                  as
                  credit / Debit card details, to process your transaction securely.
                </li>
                <li>Usage Information: We may collect information about how you use the Senderrand app, including your IP
                  address, device information, pages visited, and actions taken on our platform.</li>
                <li>Location Information: If you enable location services on your device, we may collect and store your
                  location information to provide location-based services, such as estimating delivery times.
                </li>
                <li>Marketing Channels: We may collect information about your preferences for receiving marketing
                  communications from us, such as email, text messages, or other channels.</li>
              </ul>

            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">How We Use Your Information</h5>
              <p>We use the information we collect to:</p>
              <ol>
                <li>Provide and improve our services: We use your information to process orders, communicate with you, and
                  personalize your experience with the Senderrand app. We also use it to improve our platform, services,
                  and
                  customer support.</li>
                <li>Payment processing: We use your payment information to process transactions securely and prevent
                  fraud.
                </li>
                <li>Analytics: We use usage information to analyze trends, track user activity, and gather demographic
                  information for marketing and research purposes.</li>
                <li>Communication: We may use your email address and phone number to send you important updates about your
                  orders, promotions, and other service-related communications through your preferred marketing channels.
                </li>
                <li>Language Preference: We may collect information about your preferred language to communicate
                  effectively
                  with you.</li>
                <li>Legal Requirements: We may use and disclose your information as required by law or to protect our
                  legal
                  rights, such as responding to legal requests or preventing fraud.</li>
              </ol>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Information Sharing</h5>
              <p>We do not sell, rent, or trade your personal information with third parties for their marketing purposes.
                We may share your information with third-party service providers who assist us in operating our platform
                and
                providing our services. These service providers are contractually obligated to protect your information
                and
                may only use it for the purposes we specify.
              </p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Data Security</h5>
              <p>We implement industry-standard security measures to protect your personal information from unauthorized
                access, disclosure, alteration, and destruction. However, please note that no method of transmission or
                storage is completely secure, and we cannot guarantee the absolute security of your information.</p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Your Rights</h5>
              <p>You have the right to review, update, and delete the personal information we have collected from you. You
                can do this by logging into your Senderrand account or contacting us directly. You also have the right to
                opt out of receiving marketing communications from us through your preferred marketing channels.</p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Children's Privacy</h5>
              <p>Senderrand is not intended for use by individuals under the age of 18. We do not knowingly collect
                personal
                information from children. If we become aware that we have collected personal information from a child, we
                will take steps to delete the information as soon as possible.</p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Changes to this Privacy Policy</h5>
              <p>We may update this Privacy Policy from time to time. Any changes will be posted on our platform, and we
                will notify you of any material changes via email or through other means. Your continued use of the
                Senderrand app after any changes to this policy constitutes your acceptance of the updated policy.
              </p>
            </div>
          </div>

          <div v-if="activeLink == 'Runner'">

            <p>At Senderrand, we value the privacy and security of our runners' personal information. This privacy policy
              outlines how we collect, use, and protect your information as a runner on our platform.
            </p>

            <div class="mt-4">
              <h5 class="font-weight-bold">Information We Collect</h5>
              <p>When you register as a runner on the Senderrand app, we may collect the following types of information:
              </p>
              <ul>
                <li>Personal Information: We may collect personal information such as your name, email address, phone
                  number, profile photo, and payment information (e.g., bank account or PayPal details) for payment
                  processing.</li>

                <li>Location Information: In order to fulfill user requests, our app constantly uses your location in the
                  background while you are logged in as a runner. This allows us to match you with user requests for
                  errand services based on your proximity to the task location.</li>

                <li>Transaction Information: We may collect information about the tasks you complete, including the
                  location, time, and details of each task, as well as any ratings or feedback provided by users.</li>
                <li>Communication Information: We may collect information about your communication with users, including
                  text or voice messages, as well as any support inquiries or feedback provided by users.</li>
                <li>Device Information: We may collect information about the device you use to access the Senderrand app,
                  including the device type, operating system, unique device identifiers, and mobile network information.
                </li>
              </ul>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">How We Use Your Information</h5>
              <p>We use the information we collect from runners for the following purposes:</p>
              <ol>
                <li>Task Assignment: We use your location information to match you with user requests for errand services
                  based on your proximity to the task location.
                </li>
                <li>Task Completion: We use your information to track the tasks you complete, including the location,
                  time, and details of each task, to ensure efficient and accurate task completion.
                </li>
                <li>Communication: We may use your email address, phone number, and in-app messaging to communicate with
                  you about task assignments, updates, and other service-related communications.
                </li>
                <li>Payment Processing: We may use your payment information to process payments for the tasks you complete
                  as a runner.
                </li>
                <li>Service Improvement: We may use your information to analyze trends, track user activity, gather
                  feedback, and improve our platform and services.</li>
                <li>Marketing: We may use your information, including your email address and phone number, to send you
                  marketing communications about Senderrand, including promotions, special offers, and updates, via email,
                  SMS, or other marketing channels. You have the option to opt-out of receiving marketing communications
                  at any time.
                </li>
                <li>Legal Requirements: We may use and disclose your information as required by law or to protect our
                  legal rights, such as responding to legal requests or preventing fraud.
                </li>
              </ol>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Information Sharing</h5>
              <p>We do not sell, rent, or trade your personal information with third parties for their marketing purposes.
                We may share your information with users who request your services as a runner on our platform. We may
                also share your information with third-party service providers who assist us in operating our platform and
                providing our services, such as payment processors, customer support providers, and data analytics
                providers. These service providers are contractually obligated to protect your information and may only
                use it for the purposes we specify.</p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Location Information</h5>
              <p>As a runner on the Senderrand app, your location information is constantly used in the background to
                match you with user requests for errand services based on your proximity to the task location. This
                information is essential for the proper functioning of our platform and to ensure timely and efficient
                task completion.</p>
            </div>


            <div class="mt-4">
              <h5 class="font-weight-bold">Data Security
              </h5>
              <p>We implement reasonable security measures to protect your personal information from unauthorized access,
                disclosure, alteration, and destruction. However, please note that no method of transmission over the
                internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your
                information.</p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Children's Privacy</h5>
              <p>Our services are not intended for use by children under the age of 18. We do not knowingly collect
                personal information from children. If you are a parent or guardian and believe that your child has
                provided us with personal information, please contact us immediately. If we become aware that we have
                collected personal information from a child under the age of 18, we will take steps to delete such
                information from our records.</p>
            </div>

            <div class="mt-4">
              <h5 class="font-weight-bold">Changes to This Privacy Policy
              </h5>
              <p>We may update this privacy policy from time to time to reflect changes in our business practices or legal
                requirements. We will notify you of any material changes to this policy by posting the updated policy on
                our app or by other means of communication. Your continued use of the Senderrand app after any changes to
                this policy constitutes your acceptance of the updated policy.</p>
            </div>

          </div>
        </section>
      </div>

    </div>
  </div>
</template>

<script>
import CustomNav from "../components/CustomNav.vue";

export default {
  components: {
    CustomNav,
  },
  data() {
    return {
      activeLink: 'Runner'
    }
  },
  methods: {
    setActiveLink(link) {
      this.activeLink = link;
    },
  },
}
</script>
<style lang="scss">
#privacy-policy {
  background-color: #EBF7FB;

  .back-button {
    font-size: 18px;
    cursor: pointer;
  }

  #paper {
    min-height: 80vh;
    background: #FFFFFF;
    box-shadow: 0 1px 6px rgba(57, 73, 76, .35);
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 1px;
    margin-bottom: 5rem;
    width: 70vw;
  }

  @media (max-width: 765px) {
    #paper {
      width: 100vw;
    }
  }

  .page-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .list-group-item.active {
    background-color: #fff;
    color: $primary-color;
    border-left: 5px solid #3995b8;
  }

  .list-group-item {
    background: transparent;
    border: none;
    color: #184859;
    font-weight: 600;
  }

  p,
  li {
    font-size: 0.9rem;
  }

  .senderrand-nav {
    width: 100%;
    background: #0A78A1;
  }

  .container {
    max-width: 100% !important;
    padding: 0 3rem;
  }
}
</style>